import image1 from "./assets/Images/cabinate1.webp";
import image2 from "./assets/Images/cabinate2.webp";
import image3 from "./assets/Images/cabinate3.webp";
import image4 from "./assets/Images/cabinate4.webp";
import image5 from "./assets/Images/cabinate5.webp";
import image6 from "./assets/Images/cabinate6.webp";
import image7 from "./assets/Images/cabinate7.webp";
import image8 from "./assets/Images/cabinate8.webp";
import image9 from "./assets/Images/cabinate9.webp";
import image10 from "./assets/Images/cabinate10.webp";
import image11 from "./assets/Images/cabinate11.webp";
import image12 from "./assets/Images/cabinate12.webp";
import image13 from "./assets/Images/cabinate13.webp";
import image14 from "./assets/Images/cabinate14.webp";
import image15 from "./assets/Images/cabinate15.webp";
import image16 from "./assets/Images/cabinate16.webp";
import image17 from "./assets/Images/cabinate17.webp";
import image18 from "./assets/Images/cabinate18.webp";
import image19 from "./assets/Images/cabinate19.webp";
import image20 from "./assets/Images/cabinate20.webp";

import image21 from "./assets/Images/1l.webp";
import image22 from "./assets/Images/2l.webp";
import image23 from "./assets/Images/3l.webp";
import image24 from "./assets/Images/4l.webp";
import image25 from "./assets/Images/5l.webp";
import image26 from "./assets/Images/6l.webp";
import image27 from "./assets/Images/7l.webp";
import image28 from "./assets/Images/8l.webp";
import image29 from "./assets/Images/9l.webp";
import image30 from "./assets/Images/10l.webp";
import image31 from "./assets/Images/11l.webp";
import image32 from "./assets/Images/12l.webp";
import image33 from "./assets/Images/13l.webp";
import image34 from "./assets/Images/14l.webp";
import image35 from "./assets/Images/15l.webp";
import image36 from "./assets/Images/16l.webp";
import image37 from "./assets/Images/17l.webp";
import image38 from "./assets/Images/18l.webp";
import image39 from "./assets/Images/19l.webp";
import image40 from "./assets/Images/20l.webp";
import image41 from "./assets/Images/21l.webp";
import image42 from "./assets/Images/22l.webp";

import image45 from "./assets/Images/25l.webp";
import image46 from "./assets/Images/26l.webp";

import image53 from "./assets/Images/33l.webp";

import image55 from "./assets/Images/35l.webp";

import image67 from "./assets/Images/47l.webp";





const newDescription = ` The frame is 18 mm thick and made from MDF, HDHMR, BWR Ply, BWP Ply, or Bolio Board. 
 A 0.8 mm laminate sheet (liner) is applied to the inner wall of the board and the backside of the shutter board.
 The backside of the cabinet features 6 mm MDF, HDHMR, BWR Ply, BWP Ply, or Bolio Board with a 0.8 mm laminate.`;

const newDescription2 = ` Soft-close 165° hinges
 Wooden inner drawer with telescopic soft-close slides (60 kg capacity, zinc-coated)`;

export const products = [
  {
    id: 1,
    name: "Cabinate 1",
    price: 101,
    image: image1,
    img: image21,
    category: "Acrylic",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "Cabinate-1",
  },
  {
    id: 2,
    name: "Cabinate 2",
    price: 101,
    image: image2,
    img: image22,
    category: "Acrylic",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "Cabinate-2",
  },
  {
    id: 3,
    name: "Cabinate 3",
    price: 101,
    image: image3,
    img: image23,
    category: "Acrylic",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "Cabinate-3",
  },
  {
    id: 4,
    name: "Cabinate 4",
    price: 101,
    image: image4,
    img: image24,
    category: "Acrylic",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "Cabinate-4",
  },
  {
    id: 5,
    name: "Cabinate 5",
    price: 101,
    image: image5,
    img: image25,
    category: "Acrylic",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "Cabinate-5",
  },
  {
    id: 6,
    name: "Cabinate 6",
    price: 101,
    image: image6,
    img: image26,
    category: "Gloss",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "Cabinate-6",
  },
  {
    id: 7,
    name: "Cabinate 7",
    price: 101,
    image: image7,
    img: image27,
    category: "Gloss",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "Cabinate-7",
  },
  {
    id: 8,
    name: "Cabinate 8",
    price: 101,
    image: image8,
    img: image28,
    category: "Gloss",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "Cabinate-8",
  },
  {
    id: 9,
    name: "Cabinate 9",
    price: 101,
    image: image9,
    img: image29,
    category: "Gloss",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "Cabinate-9",
  },
  {
    id: 10,
    name: "Cabinate 10",
    price: 101,
    image: image10,
    img: image30,
    category: "Gloss",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "Cabinate-10",
  },
  {
    id: 11,
    name: "Cabinate 11",
    price: 101,
    image: image11,
    img: image31,
    category: "Matte",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "Cabinate-11",
  },
  {
    id: 12,
    name: "Cabinate 12",
    price: 101,
    image: image12,
    img: image32,
    category: "Matte",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "Cabinate-12",
  },
  {
    id: 13,
    name: "Cabinate 13",
    price: 101,
    image: image13,
    img: image33,
    category: "Matte",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "Cabinate-13",
  },
  {
    id: 14,
    name: "Cabinate 14",
    price: 101,
    image: image14,
    img: image34,
    category: "Matte",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "Cabinate-14",
  },
  {
    id: 15,
    name: "Cabinate 15",
    price: 101,
    image: image15,
    img: image35,
    category: "Matte",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "Cabinate-15",
  },
  {
    id: 16,
    name: "Cabinate 16",
    price: 101,
    image: image16,
    img: image36,
    category: "Matte",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "Cabinate-16",
  },
  {
    id: 17,
    name: "Cabinate 17",
    price: 101,
    image: image17,
    img: image37,
    category: "Matte",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "Cabinate-17",
  },
  {
    id: 18,
    name: "Cabinate 18",
    price: 101,
    image: image18,
    img: image38,
    category: "Matte",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "Cabinate-18",
  },
  {
    id: 19,
    name: "Cabinate 19",
    price: 101,
    image: image19,
    img: image39,
    category: "Matte",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "Cabinate-19",
  },
  {
    id: 20,
    name: "Cabinate 20",
    price: 101,
    image: image20,
    img: image40,
    category: "Matte",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "Cabinate-20",
  },
  
  {
    id: 21,
    name: "Cabinate 20",
    price: 101,
    image: image20,
    img: image41,
    category: "Matte",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "Cabinate-20",
  },
  
  {
    id: 22,
    name: "Cabinate 21",
    price: 101,
    image: image21,
    img: image42,
    category: "Matte",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "Cabinate-21",
  },
  
  
 
  
  {
    id: 25,
    name: "Cabinate 21",
    price: 101,
    image: image21,
    img: image45,
    category: "Matte",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "Cabinate-21",
  },
  
  {
    id: 26,
    name: "Cabinate 21",
    price: 101,
    image: image21,
    img: image46,
    category: "Matte",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "Cabinate-21",
  },
  

  

  



  
  {
    id: 33,
    name: "Cabinate 21",
    price: 101,
    image: image21,
    img: image53,
    category: "Matte",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "Cabinate-21",
  },
  
  {
    id: 34,
    name: "Cabinate 21",
    price: 101,
    image: image21,
    img: image55,
    category: "Matte",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "Cabinate-21",
  },
  
 
  
  
  
  
  
  
  

 



 
  {
    id: 47,
    name: "Cabinate 21",
    price: 101,
    image: image21,
    img: image67,
    category: "Matte",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "Cabinate-21",
  },

];


