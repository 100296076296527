import React, { useState, useEffect, useRef } from "react";
import "./Fullscreen.css";

const FullScreenSlider = ({ images = [], initialIndex = 0, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const [isPaused, setIsPaused] = useState(false);
  const [isMusicOn, setIsMusicOn] = useState(false);
  const audioRef = useRef(null);
  const sliderRef = useRef(null);

  // Toggle music playback
  const toggleMusic = () => {
    const audio = audioRef.current;
    if (audio) {
      if (isMusicOn) audio.pause();
      else
        audio.play().catch((err) => console.warn("Error playing audio:", err));
      setIsMusicOn((prev) => !prev);
    }
  };

  // Toggle fullscreen mode
  const toggleFullScreen = () => {
    if (sliderRef.current) {
      if (!document.fullscreenElement) {
        sliderRef.current.requestFullscreen().catch((err) => {
          console.error("Error entering fullscreen mode:", err);
        });
      } else {
        document.exitFullscreen().catch((err) => {
          console.error("Error exiting fullscreen mode:", err);
        });
      }
    }
  };

  // Auto-play slider unless paused
  useEffect(() => {
    if (isPaused || images.length === 0) return;

    const sliderInterval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000);

    return () => clearInterval(sliderInterval);
  }, [isPaused, images.length]);

  // Keyboard navigation
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowRight") {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      } else if (event.key === "ArrowLeft") {
        setCurrentIndex((prevIndex) =>
          prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
      } else if (event.key === "Escape") {
        onClose();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [images.length, onClose]);

  if (!images || images.length === 0) {
    return (
      <section className="fullscreen-slider" aria-label="Image slider">
        <p>No images to display.</p>
        <button
          className="close-button"
          onClick={onClose}
          aria-label="Close slider"
        >
          &times;
        </button>
      </section>
    );
  }

  return (
    <section
      ref={sliderRef}
      className="fullscreen-slider"
      aria-label="Image slider"
      // onMouseEnter={() => setIsPaused(true)}
      // onMouseLeave={() => setIsPaused(false)}
    >
      {/* Header */}
      <header className="slider-header">
        {/* Music Toggle Button */}
        <div
          className="music-toggle"
          onClick={toggleMusic}
          aria-label="Toggle Music"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 20 20"
            className={`text-4xl transition-transform ${
              isMusicOn ? "animate-wave" : ""
            }`}
            style={{ maxWidth: "100%", color: "white" }}
          >
            <g fill="none" fillRule="evenodd">
              <g fill="white" transform="translate(-260 -3799)">
                <g transform="translate(56 160)">
                  <path d="M211.988 3643h10.01v-2h-10.01v2zm-2.002-4v12.535c-1.001-.34-1.26-.535-1.988-.535a3.996 3.996 0 0 0-3.998 4 3.994 3.994 0 1 0 7.988 0v-10h10.01v6.535c-1.001-.34-1.26-.535-1.988-.535a3.996 3.996 0 0 0-3.998 4 3.994 3.994 0 1 0 7.988 0v-16h-14.014z"></path>
                </g>
              </g>
            </g>
          </svg>
        </div>

        {/* Fullscreen Button */}
        <div
          className="fullscreen-toggle"
          onClick={toggleFullScreen}
          aria-label="Toggle Fullscreen"
        >
          <svg
  xmlns="http://www.w3.org/2000/svg"
  width="35"
  height="35"
  viewBox="0 0 24 24"
  fill="white"
>
  <path d="M3 3v6h2V5h4V3H3zm16 0h-6v2h4v4h2V3zm-6 16v2h6v-6h-2v4h-4zm-6-4H3v6h6v-2H5v-4z" />
</svg>

        </div>

        {/* Close button */}
        <button
          className="close-button"
          onClick={onClose}
          aria-label="Close slider"
        >
          &times;
        </button>
      </header>

      {/* Hidden Audio Element */}
      <audio ref={audioRef} src={require("../../assets/Images/song.mp3")} />

      {/* Image Section */}
      <figure className="fullscreen-image-container">
        {/* Logo inside image */}
        <div className="logo-container">
          <img
            src={require("../../assets/Images/bgrlogo.png")}
            alt="Logo"
            className="logo"
          />
        </div>

        {/* Image */}
        <img
          src={images[currentIndex]}
          alt={`Slide ${currentIndex + 1} of ${images.length}`}
          className="fullscreen-image"
        />
        <figcaption>
          Image {currentIndex + 1} of {images.length}
        </figcaption>
      </figure>

      {/* Navigation */}
      <nav className="navigation" aria-label="Slider navigation">
        <button
          className="prev-button"
          onClick={() =>
            setCurrentIndex(
              currentIndex === 0 ? images.length - 1 : currentIndex - 1
            )
          }
          aria-label="Previous Slide"
        >
          &#10094;
        </button>
        <button
          className="next-button"
          onClick={() =>
            setCurrentIndex((currentIndex + 1) % images.length)
          }
          aria-label="Next Slide"
        >
          &#10095;
        </button>
      </nav>
    </section>
  );
};

export default FullScreenSlider;
