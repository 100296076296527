import React, { useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="bg-white shadow-md py-4 mb-10  ">
      <div className="mx-auto flex items-center justify-between px-4 md:px-8">
        {/* Logo */}
        <div className="text-2xl font-bold text-orange-500">
          <Link to="/" aria-label="Home">
            <img
              className="h-10"
              src={require("../../assets/Images/img.webp")}
              alt="Company Logo"
            />
          </Link>
        </div>

        {/* Desktop Navigation */}
        <nav className="hidden md:flex space-x-6" aria-label="Main Navigation">
          <Link to="/" className="text-gray-700 hover:text-orange-500">
            Home
          </Link>
          <Link to="/contact" className="text-gray-700 hover:text-orange-500">
            Contact
          </Link>
          <Link to="/gallery" className="text-gray-700 hover:text-orange-500">
           Gallery
          </Link>
        </nav>

        {/* Hamburger Menu */}
        <div className="md:hidden w-1/2 flex items-center justify-end">
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="text-orange-500"
            aria-label="Toggle Navigation Menu"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 5.25h16.5m-16.5 6h16.5m-16.5 6h16.5"
              />
            </svg>
          </button>
        </div>

        {/* Get a Quote Button */}
        <div>
          <Link
            to="/contact"
            className="hidden md:block bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600"
            aria-label="Get a Quote"
          >
            Get a Quote
          </Link>
        </div>
      </div>

      {/* Mobile Navigation */}
      {isMenuOpen && (
        <nav
          className="md:hidden bg-white shadow-md mt-6 px-4 py-2 space-y-4 rounded-md"
          aria-label="Mobile Navigation"
        >
          <Link
            to="/"
            className="block text-gray-700 hover:text-orange-500"
            onClick={() => setIsMenuOpen(false)}
          >
            Home
          </Link>
          <Link
            to="/contact"
            className="block text-gray-700 hover:text-orange-500"
            onClick={() => setIsMenuOpen(false)}
          >
            Contact
          </Link>

          <Link
            to="/gallery"
            className="block text-gray-700 hover:text-orange-500"
            onClick={() => setIsMenuOpen(false)}
          >
            Gallery
          </Link>
        </nav>
      )}
    </header>
  );
};

export default Header;

