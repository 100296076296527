import { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import img1 from "../../assets/Images/11l.webp"; // Replace with your actual image path
import img2 from "../../assets/Images/12l.webp"; // Replace with your actual image path

gsap.registerPlugin(ScrollTrigger);

const SafetySection = () => {
  return (
    <section
      className="safety-section w-full flex items-center justify-center"
      aria-labelledby="safety-heading"
      style={{
        backgroundImage: `url(${img2})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "500px",
      }}
    >
    
      <div className=" mx-auto w-full flex items-center justify-center py-12 px-6 md:px-10 lg:px-16">
      
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
          {/* Text Section */}
          <article
            className="safety-text bg-transparent bg-opacity-80 rounded-lg shadow-lg flex flex-col items-center lg:items-start justify-center text-center lg:text-left p-6 sm:p-8"
            aria-labelledby="promo-videos-title"
            aria-describedby="promo-videos-description"
          >
            <header>
              <h2
                id="promo-videos-title"
                className=" text-gray-100 text-4xl sm:text-4xl lg:text-5xl flex flex-col  font-semibold mb-4 text-gray-800"
              >
                <span className="capitalize font-medium text-sm text-orange-600">
                  {/* Promo Videos\ */}
                </span>
                {/* Ensuring a Safe Experience from Design to Installation */}
              </h2>
            </header>
            <p
              id="promo-videos-description"
              className="text-gray-200 mb-6 text-base sm:text-lg lg:text-sm"
            >
              {/* Experience unparalleled safety and precision in every step. From
              innovative designs to flawless installations, we ensure your
              satisfaction and peace of mind. */}
            </p>
            <p
              id="promo-videos-description"
              className="text-gray-200 mb-6 text-base sm:text-lg lg:text-sm"
            >
              {/* Experience unparalleled safety and precision in every step. From
              innovative designs to flawless installations, we ensure your
              satisfaction and peace of mind. */}
            </p>

            <p
              id="promo-videos-description"
              className="text-gray-200 mb-6 text-base sm:text-lg lg:text-sm"
            >
              {/* Experience unparalleled safety and precision in every step. From
              innovative designs to flawless installations, we ensure your
              satisfaction and peace of mind. */}
            </p>
            {/* <a
              href="#more-details"
              className="bg-orange-600 text-white px-6 py-3 rounded-full hover:bg-orange-500 transition duration-300"
            >
              Find Out More
            </a> */}
          </article>

          {/* Image Section */}
          <div className="safety-img flex justify-center items-center mt-20">
            <img
              src={img1}
              alt="Illustration of safe design and installation process"
              className="h-auto w-full max-w-sm sm:max-w-md lg:max-w-lg rounded-lg shadow-lg"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SafetySection;
