import React from 'react'
import Header from "../Home/Header"
import Image from './Image'

const Gallery = () => {
  return (
    <>
      <Header />
      <Image />
    </>
  )
}

export default Gallery