import React from "react";
import "./contact.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faTwitter, faInstagram, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import EnvelopeAnimation from "./Envolap";
import Header from "../Home/Header";
import Swal from "sweetalert2";

const ContactForm = () => {
  const handleFocus = (e) => {
    e.target.parentNode.classList.add("focus");
  };

  const handleBlur = (e) => {
    if (e.target.value === "") {
      e.target.parentNode.classList.remove("focus");
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    // Add access key for API
    formData.append("access_key", "44165200-6289-4472-8486-185d74818a5e");

    const jsonData = JSON.stringify(Object.fromEntries(formData));

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: jsonData,
      });

      const result = await response.json();

      if (result.success) {
        Swal.fire({
          title: "Success!",
          text: "Your message has been sent.",
          icon: "success",
          confirmButtonText: "OK",
          customClass: {
            popup: "custom-orange-popup", // Apply custom CSS class
            title: "custom-orange-title",
            confirmButton: "custom-orange-button",
          },
        });
        event.target.reset(); // Reset the form after successful submission
      } else {
        Swal.fire({
          title: "Error",
          text: "Form submission failed. Please try again.",
          icon: "error",
          confirmButtonText: "OK",
        });
        console.error("Form submission failed:", result);
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "An unexpected error occurred. Please try again later.",
        icon: "error",
        confirmButtonText: "OK",
      });
      console.error("Error submitting form:", error);
    }
  };

  return (
    <>
      <Header />
      <div className="container">
        <span className="big-circle"></span>
        <img src="img/shape.png" className="square" alt="Decorative Shape" />

        <div className="form">
          {/* Contact Info Section */}
          <div className="contact-info">
            <h3 className="title">Let's get in touch</h3>
            <p className="text">
              Feel free to reach out for any queries or collaboration opportunities. We'd love to hear from you!
            </p>

            <div className="info">
              <EnvelopeAnimation />
            </div>

            <div className="social-media">
              <p>Connect with us:</p>
              <div className="social-icons">
                <a href="https://facebook.com" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
                <a href="https://twitter.com" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a href="https://instagram.com" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a href="https://linkedin.com" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
              </div>
            </div>
          </div>

          {/* Contact Form Section */}
          <div className="contact-form">
            <span className="circle one"></span>
            <span className="circle two"></span>

            <form autoComplete="off" onSubmit={onSubmit}>
              <h3 className="title">Contact us</h3>

              <div className="input-container">
                <input
                  type="text"
                  name="name"
                  className="input"
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  required
                />
                <label>Name</label>
                <span>Username</span>
              </div>

              <div className="input-container">
                <input
                  type="email"
                  name="email"
                  className="input"
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  required
                />
                <label>Email</label>
                <span>Email</span>
              </div>

              <div className="input-container">
                <input
                  type="tel"
                  name="phone"
                  className="input"
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  required
                />
                <label>Phone</label>
                <span>Phone</span>
              </div>

              <div className="input-container textarea">
                <textarea
                  name="message"
                  className="input"
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  required
                ></textarea>
                <label>Message</label>
                <span>Message</span>
              </div>

              <input type="submit" value="Send" className="btn" />
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
