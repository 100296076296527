// import React, { useEffect } from "react";
// import { gsap } from "gsap";
// import leminenece from "../../assets/Images/img.jpeg";
// import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from "react-icons/fa";
// import { Link } from "react-router-dom";

// const Footer = () => {
//   useEffect(() => {
//     // GSAP Animations
//     gsap.fromTo(
//       ".footer-heading",
//       { opacity: 0, y: 50 },
//       { opacity: 1, y: 0, duration: 1, stagger: 0.2 }
//     );

//     gsap.fromTo(
//       ".footer-item",
//       { opacity: 0, x: 50 },
//       { opacity: 1, x: 0, duration: 1, delay: 0.5, stagger: 0.2 }
//     );
//   }, []);

//   return (
//     <footer className="bg-white text-black w-full overflow-hidden">
//       <div className="mx-auto px-8 py-12">
//         {/* Header Section */}
//         <div className="text-center mb-12 flex flex-col items-center justify-center">
//           <p className="text-orange-500 font-medium mb-4 footer-heading">
//             You Dream It, We Design It
//           </p>
//           <h2 className="text-4xl md:text-5xl font-bold mb-6 text-black w-2/3 footer-heading">
//             Build Your Dream Kitchen with Us
//           </h2>
//           <Link
//             to="/contact"
//             className="bg-orange-500 text-white px-8 py-3 rounded-full hover:bg-orange-600 transition footer-heading"
//           >
//             Schedule a Chat
//           </Link>
//         </div>

//         {/* Footer Grid */}
//         <div className="grid grid-cols-1 md:grid-cols-2 gap-6 text-left bg-orange-100 p-8 rounded-lg">
//           {/* Logo & Contact Info */}
//           <div className="footer-item">
//             <h3 className="sr-only">Contact Information</h3>
//             <img
//               src={leminenece}
//               alt="Laminance - Your Kitchen Design Partner"
//               className="mb-4"
//             />
//             <address className="text-gray-400 not-italic">
//               Laminance
//               <br />
//               418 Rt 23, Franklin, NJ, 07416
//             </address>
//             <p className="text-gray-400 mt-4">
//               Phone: <a href="tel:+1234567890">(862) 450-6069</a>
//               <br />
//                <a className="text-base" href="Contactus@laminance.com">Contactus@laminance.com</a>
//             </p>
//           </div>

//           {/* About Us */}
//           <nav className="footer-item">
//             <h3 className="text-lg font-bold mb-4 text-orange-500">About Us</h3>
//             <ul className="space-y-2">
//               <li>
//                 <Link to="" className="hover:text-orange-500 transition">
//                   About Us
//                 </Link>
//               </li>
//               <li>
//                 <Link to="" className="hover:text-orange-500 transition">
//                   Our Team
//                 </Link>
//               </li>
//               <li>
//                 <Link to="" className="hover:text-orange-500 transition">
//                   Testimonials
//                 </Link>
//               </li>
//               <li>
//                 <Link to="" className="hover:text-orange-500 transition">
//                   Contact
//                 </Link>
//               </li>
//             </ul>
//           </nav>

//           {/* Explore */}
//           {/* <nav className="footer-item">
//             <h3 className="text-lg font-bold mb-4 text-orange-500">Explore</h3>
//             <ul className="space-y-2">
//               <li>
//                 <Link to="/kitchen-cabinets" className="hover:text-orange-500 transition">
//                   Kitchen Cabinets
//                 </Link>
//               </li>
//               <li>
//                 <Link to="/countertops" className="hover:text-orange-500 transition">
//                   Counter Tops
//                 </Link>
//               </li>
//               <li>
//                 <Link to="/bathroom-vanities" className="hover:text-orange-500 transition">
//                   Bathroom Vanities
//                 </Link>
//               </li>
//               <li>
//                 <Link to="/vanity-tops" className="hover:text-orange-500 transition">
//                   Vanity Tops
//                 </Link>
//               </li>
//             </ul>
//           </nav> */}

//           {/* Quick Links */}
//           {/* <nav className="footer-item">
//             <h3 className="text-lg font-bold mb-4 text-orange-500">Quick Links</h3>
//             <ul className="space-y-2">
//               <li>
//                 <Link to="/faq" className="hover:text-orange-500 transition">
//                   FAQ
//                 </Link>
//               </li>
//               <li>
//                 <Link to="/blog" className="hover:text-orange-500 transition">
//                   Blog
//                 </Link>
//               </li>
//               <li>
//                 <Link to="/design-quote" className="hover:text-orange-500 transition">
//                   Design & Quote
//                 </Link>
//               </li>
//               <li>
//                 <Link to="/warranty" className="hover:text-orange-500 transition">
//                   Warranty
//                 </Link>
//               </li>
//               <li>
//                 <Link to="/trade-partner" className="hover:text-orange-500 transition">
//                   Trade Partner
//                 </Link>
//               </li>
//             </ul>
//           </nav> */}

//           {/* Social Media */}
//           {/* <div className="footer-item">
//             <h3 className="text-lg font-bold mb-4 text-orange-500">Follow Us</h3>
//             <div className="flex space-x-4">
//               <a
//                 href="#"
//                 className="hover:text-orange-500 transition"
//                 aria-label="Follow us on Facebook"
//               >
//                 <FaFacebookF />
//               </a>
//               <a
//                 href="#"
//                 className="hover:text-orange-500 transition"
//                 aria-label="Follow us on Twitter"
//               >
//                 <FaTwitter />
//               </a>
//               <a
//                 href="#"
//                 className="hover:text-orange-500 transition"
//                 aria-label="Follow us on Instagram"
//               >
//                 <FaInstagram />
//               </a>
//               <a
//                 href="#"
//                 className="hover:text-orange-500 transition"
//                 aria-label="Follow us on LinkedIn"
//               >
//                 <FaLinkedinIn />
//               </a>
//             </div>
//           </div> */}
//         </div>
//       </div>
//     </footer>
//   );
// };

// export default Footer;

import React, { useEffect } from "react";
import { gsap } from "gsap";
import leminenece from "../../assets/Images/img.webp";
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  useEffect(() => {
    // GSAP Animations
    gsap.fromTo(
      ".footer-heading",
      { opacity: 0, y: 50 },
      { opacity: 1, y: 0, duration: 1, stagger: 0.2 }
    );

    gsap.fromTo(
      ".footer-item",
      { opacity: 0, x: 50 },
      { opacity: 1, x: 0, duration: 1, delay: 0.5, stagger: 0.2 }
    );
  }, []);

  return (
    <footer className="bg-white text-black w-full overflow-hidden">
      <div className="max-w-7xl mx-auto px-6 md:px-12 py-12">
        {/* Header Section */}
        <div className="text-center mb-12 flex flex-col items-center justify-center">
          <p className="text-orange-500 font-medium mb-4 footer-heading">
            You Dream It, We Design It
          </p>
          <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-6 text-black w-full md:w-4/5 lg:w-2/3 footer-heading">
            Build Your Dream Kitchen with Us
          </h2>
          <Link
            to="/contact"
            className="bg-orange-500 text-white px-6 py-3 rounded-full hover:bg-orange-600 transition footer-heading"
          >
            Schedule a Chat
          </Link>
        </div>

        {/* Footer Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 gap-6 bg-orange-100 p-6 sm:p-8 rounded-lg">
          {/* Logo & Contact Info */}
          <div className=" footer-item md:flex items-center justify-evenly">
            <img
              src={leminenece}
              alt="Laminance - Your Kitchen Design Partner"
              className="mb-4 w-32 sm:w-40 h-auto"
            />
            <address className="text-gray-600 not-italic text-sm">
              Laminance
              <br />
              418 Rt 23, Franklin, NJ, 07416
            </address>
            <p className="text-gray-600 mt-4 text-sm">
              Phone: <a href="tel:+1234567890">(862) 450-6069</a>
              <br />
              <a href="mailto:Contactus@laminance.com" className="text-orange-500">
                Contactus@laminance.com
              </a>
            </p>
          </div>

          {/* Social Media Links */}
          {/* <div className="footer-item">
            <h3 className="text-lg font-semibold mb-4">Follow Us</h3>
            <div className="flex space-x-4">
              <a href="#" className="text-gray-600 hover:text-orange-500">
                <FaFacebookF size={20} />
              </a>
              <a href="#" className="text-gray-600 hover:text-orange-500">
                <FaTwitter size={20} />
              </a>
              <a href="#" className="text-gray-600 hover:text-orange-500">
                <FaInstagram size={20} />
              </a>
              <a href="#" className="text-gray-600 hover:text-orange-500">
                <FaLinkedinIn size={20} />
              </a>
            </div>
          </div> */}

          {/* Quick Links */}
          {/* <div className="footer-item">
            <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
            <ul className="text-gray-600 space-y-2 text-sm">
              <li>
                <Link to="/about" className="hover:text-orange-500">About Us</Link>
              </li>
              <li>
                <Link to="/services" className="hover:text-orange-500">Services</Link>
              </li>
              <li>
                <Link to="/portfolio" className="hover:text-orange-500">Portfolio</Link>
              </li>
              <li>
                <Link to="/contact" className="hover:text-orange-500">Contact Us</Link>
              </li>
            </ul>
          </div> */}

          {/* Newsletter Signup */}
          {/* <div className="footer-item">
            <h3 className="text-lg font-semibold mb-4">Subscribe to Our Newsletter</h3>
            <form className="flex flex-col space-y-4">
              <input
                type="email"
                placeholder="Enter your email"
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
              />
              <button
                type="submit"
                className="bg-orange-500 text-white px-6 py-2 rounded-full hover:bg-orange-600 transition"
              >
                Subscribe
              </button>
            </form>
          </div> */}
        </div>  
      </div>
    </footer>
  );
};

export default Footer;
