import React, { useState, useEffect, useRef, useMemo } from 'react';
import './slider.css';

const ImageSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMusicOn, setIsMusicOn] = useState(false);
  const audioRef = useRef(null);

  // UseMemo for static slides array
  const slides = useMemo(() => [
    {
      img: require("../../../assets/Images/8l.webp"),
    },
    {
      img: require("../../../assets/Images/12l.webp"),
    },
    {
      img: require("../../../assets/Images/2l.webp"),
    
    },
    {
      img: require("../../../assets/Images/4l.webp"),
    },
  ], []);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + slides.length) % slides.length
    );
  };

  // Preload images only when currentIndex changes
  useEffect(() => {


    const nextIndex = (currentIndex + 1) % slides.length;
    const prevIndex = (currentIndex - 1 + slides.length) % slides.length;

    const preloadImage = (index) => {
      const img = new Image();
      img.src = slides[index].img;
    };

    preloadImage(nextIndex);
    preloadImage(prevIndex);
  }, [currentIndex, slides]);

  const toggleMusic = () => {
    const audio = audioRef.current;
    if (audio) {
      if (isMusicOn) audio.pause();
      else
        audio.play().catch((err) => console.warn("Error playing audio:", err));
      setIsMusicOn((prev) => !prev);
    }
  };

  // Memoized current slide to avoid recalculating
  const currentSlide = useMemo(() => slides[currentIndex], [currentIndex, slides]);

  return (
    <div className="slider" role="region" aria-label="Image Slider">
      {/* Audio Player */}
      <audio ref={audioRef} loop preload="auto" aria-hidden="true">
        <source
          src={require("../../../assets/Images/song.mp3")}
          type="audio/mp3"
        />
      </audio>

      {/* Image Slider */}
      <div className="list">
        <figure
          key={currentIndex}
          className="item active"
        >
          <img
            src={currentSlide.img}
            srcSet={`${currentSlide.img}?w=300 300w, ${currentSlide.img}?w=768 768w, ${currentSlide.img}?w=1200 1200w`}
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            loading="eager" // Eagerly load current image
          />
          <figcaption className="content">
            <h2 className="title">Elegant+Quality = Budget-Friendly Premium Kitchen</h2>
            <p className="description">"Your Kitchen Design and Modeling Simplified! Laminance offers hundreds of colors in premium European-style cabinets, full customization, and premium hardware in the most economic way."</p>
          </figcaption>
        </figure>
      </div>

      {/* Music Toggle */}
      <div
        className="absolute top-5 left-5 cursor-pointer z-10"
        onClick={toggleMusic}
        aria-label="Toggle Music"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          color="white"
          viewBox="0 0 20 20"
          id="music"
          className={`text-4xl transition-transform ${
            isMusicOn ? 'animate-wave' : ''
          }`}
          style={{ maxWidth: '100%', color: 'white' }}
        >
          <g
            id="Page-1"
            fill="none"
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
          >
            <g
              id="Dribbble-Light-Preview"
              fill="white"
              transform="translate(-260 -3799)"
            >
              <g id="icons" transform="translate(56 160)">
                <path
                  id="music-[#995]"
                  d="M211.988 3643h10.01v-2h-10.01v2zm-2.002-4v12.535c-1.001-.34-1.26-.535-1.988-.535a3.996 3.996 0 0 0-3.998 4 3.994 3.994 0 1 0 7.988 0v-10h10.01v6.535c-1.001-.34-1.26-.535-1.988-.535a3.996 3.996 0 0 0-3.998 4 3.994 3.994 0 1 0 7.988 0v-16h-14.014z"
                ></path>
              </g>
            </g>
          </g>
        </svg>
      </div>

      {/* Thumbnails */}
      <div className="thumbnail">
        {slides.map((slide, index) => (
          <button
            key={index}
            className={`item ${index === currentIndex ? 'active' : ''}`}
            aria-label={`Go to slide ${index + 1}`}
            onClick={() => setCurrentIndex(index)}
          >
            <img src={slide.img} alt={`Thumbnail ${index + 1}`} loading="lazy" />
          </button>
        ))}
      </div>

      {/* Navigation Arrows */}
      <div className="nextPrevArrows">
        <button
          className="prev"
          onClick={handlePrev}
          aria-label="Previous Slide"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="34" height="34">
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M15 19l-7-7 7-7v14z" />
          </svg>
        </button>
        <button className="next" onClick={handleNext} aria-label="Next Slide">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="34" height="34">
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M9 5l7 7-7 7V5z" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default ImageSlider;
