import React, { useState } from "react";
import "./Image.css";
import { products } from "../../Product";
import FullScreenSlider from "./FullScreenSlider";

function Image() {
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openSlider = (index) => {
    setCurrentImageIndex(index);
    setIsSliderOpen(true);
  };

  const closeSlider = () => {
    setIsSliderOpen(false);
  };

  return (
    <section className="gallery-container" aria-labelledby="gallery-title">
      <div className="images-grid" role="list">
        {products.map((product, index) => (
          <div
            key={product.id}
            className="image-wrapper"
            onClick={() => openSlider(index)}
            role="listitem"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") openSlider(index);
            }}
            aria-label={`Open fullscreen slider for ${product.description || "product image"}`}
          >
            <img
              src={product.img}
              alt={product.description || "Product image"}
              loading="lazy"
              className="gallery-image"
            />
          </div>
        ))}
      </div>

      {isSliderOpen && (
        <FullScreenSlider
          images={products.map((product) => product.img)}
          initialIndex={currentImageIndex}
          onClose={closeSlider}
        />
      )}
    </section>
  );
}

export default Image;
