import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilRuler, faTools, faShippingFast, faHeadset } from "@fortawesome/free-solid-svg-icons";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const ServicesSection = () => {
  useEffect(() => {
    // Animation for service cards
    
  }, []);

  return (
    <section
      className="py-6 bg-white services-section"
      aria-labelledby="services-heading"
    >
      <div className="mx-auto px-4 text-center max-w-6xl mb-16 ">
        <header>
          <h2
            id="services-heading"
            className="text-5xl  font-bold mb-16 text-orange-600 leading-[60px]"
          >
            Extremely High Standard of Customer Satisfaction
          </h2>
        </header>
        <div
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8"
          role="list"
        >
          <article
            className="service-card p-6 bg-orange-100 rounded-lg shadow-lg hover:shadow-xl transition duration-300"
            aria-labelledby="service1-title"
          >
            <FontAwesomeIcon
              icon={faPencilRuler}
              className="text-5xl text-orange-600 mb-4"
              alt="Custom Design Icon"
            />
            <h3
              id="service1-title"
              className="text-2xl font-bold text-gray-800"
            >
              Custom Design
            </h3>
            <p className="text-gray-600 mt-2">
              Tailored to your needs with precision and care.
            </p>
          </article>
          <article
            className="service-card p-6 bg-orange-100 rounded-lg shadow-lg hover:shadow-xl transition duration-300"
            aria-labelledby="service2-title"
          >
            <FontAwesomeIcon
              icon={faTools}
              className="text-5xl text-orange-600 mb-4"
              alt="Quality Materials Icon"
            />
            <h3
              id="service2-title"
              className="text-2xl font-bold text-gray-800"
            >
              Quality Materials
            </h3>
            <p className="text-gray-600 mt-2">
              Built with the highest-grade materials for durability.
            </p>
          </article>
          <article
            className="service-card p-6 bg-orange-100 rounded-lg shadow-lg hover:shadow-xl transition duration-300"
            aria-labelledby="service3-title"
          >
            <FontAwesomeIcon
              icon={faShippingFast}
              className="text-5xl text-orange-600 mb-4"
              alt="Fast Installation Icon"
            />
            <h3
              id="service3-title"
              className="text-2xl font-bold text-gray-800"
            >
              Fast Installation
            </h3>
            <p className="text-gray-600 mt-2">
              Quick and efficient service, saving your time.
            </p>
          </article>
          <article
            className="service-card p-6 bg-orange-100 rounded-lg shadow-lg hover:shadow-xl transition duration-300"
            aria-labelledby="service4-title"
          >
            <FontAwesomeIcon
              icon={faHeadset}
              className="text-5xl text-orange-600 mb-4"
              alt="Support Icon"
            />
            <h3
              id="service4-title"
              className="text-2xl font-bold text-gray-800"
            >
              Support
            </h3>
            <p className="text-gray-600 mt-2">
              24/7 customer support for all your queries.
            </p>
          </article>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
