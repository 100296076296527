import React from 'react'
import { Outlet } from 'react-router'
import Header from '../../../src/Component/Home/Header'


const Layout = () => {
  return (
    <div>
      <main className='w-full max-w-full m-auto '>
        <Header />
        <Outlet />
      </main>
    </div>
  )
}

export default Layout
