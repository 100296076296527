import React from "react";
import img1 from "../../assets/Images/1l.webp";
import img2 from "../../assets/Images/2l.webp";
import img3 from "../../assets/Images/3l.webp";
import img4 from "../../assets/Images/4l.webp";

const CollectionsSection = () => {
  const collections = [
    {
      title: "Country Collection",
      img: img1,
      description: "Experience rustic charm with our exclusive designs.",
    },
    {
      title: "Contemporary Collection",
      img: img2,
      description: "Explore the blend of tradition and modernity.",
    },
    {
      title: "Modern Collection",
      img: img3,
      description: "Innovative designs for contemporary living.",
    },
    {
      title: "Urban Collection",
      img: img4,
      description: "Sophisticated styles inspired by city life.",
    },
  ];

  return (
    <section
      className="py-4 bg-white"
      aria-labelledby="collections-heading"
    >
      <div className="max-w-7xl mx-auto mt-6">
        <header className="text-center mb-8 px-4">
          <h2
            id="collections-heading"
            className="text-5xl  font-bold text-orange-600"
          >
            Our Exclusive Collections
          </h2>
          <p className="text-sm text-gray-600 mt-2">
            Discover the perfect blend of style and functionality.
          </p>
        </header>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 p-8">
          {collections.map((collection, index) => (
            <article
              key={index}
              className="relative group overflow-hidden rounded-lg h-[450px] bg-gray-100 shadow-lg"
              aria-labelledby={`collection-title-${index}`}
            >
              {/* Image */}
              <figure className="h-full w-full">
                <img
                  src={collection.img}
                  alt={`Image of ${collection.title}`}
                  className="w-full h-full object-cover"
                />
                <figcaption className="sr-only">
                  {collection.title} - {collection.description}
                </figcaption>
              </figure>
              {/* Hover Content */}
              <div className="absolute inset-0 bg-gray-600 bg-opacity-80 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex flex-col justify-between items-center p-6">
                <h3
                  id={`collection-title-${index}`}
                  className="text-3xl font-bold text-white mt-4"
                >
                  {collection.title}
                </h3>
                <p className="text-lg text-white mb-4 text-center">
                  {collection.description}
                </p>
              </div>
            </article>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CollectionsSection;
