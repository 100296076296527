import React from "react";
import "./contact.css"; // Import the associated CSS file

const EnvelopeAnimation = () => {
  return (
    <div className="wrapper">
      <div className="lid one"></div>
      <div className="lid two"></div>
      <div className="envelope"></div>
      <div className="letter">
        <p>Hello</p>
      </div>
    </div>
  );
};

export default EnvelopeAnimation;
