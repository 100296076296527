import { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { motion } from "framer-motion";
import img1 from "../../assets/Images/5l.webp";
import img2 from "../../assets/Images/6l.webp";
import img3 from "../../assets/Images/7l.webp";
import img4 from "../../assets/Images/8l.webp";
import img5 from "../../assets/Images/9l.webp";
import img6 from "../../assets/Images/10l.webp";

gsap.registerPlugin(ScrollTrigger);

const ExploreSection = () => {
  useEffect(() => {
    
  }, []);

  const designs = [
    { title: "Modern Kitchen", img: img1 },
    { title: "Rustic Charm", img: img2 },
    { title: "Elegant Design", img: img3 },
    { title: "Urban Minimal", img: img4 },
    { title: "Contemporary Space", img: img5 },
    { title: "Classic Aesthetic", img: img6 },
  ];

  return (
    <section
      className="explore-section py-16 bg-white"
      aria-labelledby="explore-title"
    >
      <div className=" mx-auto px-6 flex flex-col items-center justify-center">
        <header className="text-center mb-8">
          <h1
            id="explore-title"
            className="text-orange-600 font-medium text-lg mb-4 uppercase"
          >
            Our Project
          </h1>
          <h2 className="text-5xl font-bold text-black mb-6">
            Explore Our Kitchen Designs
          </h2>
          <p className="w-full sm:w-2/3 text-gray-600 mx-auto">
            Discover stunning kitchen designs that blend functionality with
            aesthetic appeal. From modern to classic, our curated projects
            showcase expert craftsmanship and innovative design concepts.
          </p>
        </header>
        <div
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 w-full lg:w-2/3"
          role="list"
        >
          {designs.map((design, index) => (
            <motion.article
              key={index}
              className="design-card relative rounded-lg shadow-lg transform transition duration-300 hover:scale-105"
              whileHover={{ scale: 1.05 }}
              role="listitem"
            >
              {/* Image */}
              <img
                src={design.img}
                alt={`${design.title} - premium kitchen design`}
                className="rounded-lg w-full h-72 object-cover"
              />

              {/* Overlay for title */}
              <div
                className="absolute inset-0 bg-gray-600 bg-opacity-75 opacity-0 flex items-center justify-center transition-opacity duration-300 hover:opacity-100 rounded-lg"
                role="button"
                aria-label={`Learn more about ${design.title}`}
              >
                <h3 className="text-white text-xl font-bold">{design.title}</h3>
              </div>
            </motion.article>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ExploreSection;
